import {
  AppBar,
  Stack,
  SvgIcon,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as UbicoLogo } from "../../../assets/logos/ubico.svg";
import { RootState } from "../../../redux/store";
import ColorModeButton from "../../ColorModeButton";

const NavbarTop: React.FC = (): React.ReactElement => {
  const theme = useTheme();

  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const { completed_account_setup } = useSelector(
    (state: RootState) => state.account,
  );

  return !is_logged_in || !completed_account_setup ? (
    <AppBar elevation={0} sx={{ bgcolor: theme.palette.background.paper }}>
      <Toolbar variant="dense">
        <Box display="flex" flexDirection={"column"} justifyContent="center">
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={theme.spacing(2)}
            component={Link}
            to="/"
            sx={{ textDecoration: "none" }}
          >
            <SvgIcon
              component={UbicoLogo}
              sx={{ color: theme.palette.text.primary }}
              inheritViewBox
            />
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Ubuntu",
                color: theme.palette.text.primary,
                boxShadow: "none",
              }}
            >
              ubico
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <ColorModeButton/>
      </Toolbar>
    </AppBar>
  ) : (
    <></>
  );
};

export default NavbarTop;
