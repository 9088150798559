import { ContentPaste } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import { Templates } from "../loadables";
import { Route } from "./routeInterface";

export const templateRoutes: Array<Route> = [
  {
    key: "templates-route",
    label: "Templates",
    path: "templates",
    enabled: true,
    component: Templates,
    isTopTab: true,
    order: 7,
    tabInfo: {
      id: "templates",
      startIcon: <Icon component={ContentPaste} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
];
