import { Chip, Fade, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { TasksTabEnum } from "../../pages/tasks/constants/tasks";
import { RootState } from "../../redux/store";

interface TasksCountChipProps {
  type?: TasksTabEnum;
}

const TasksCountChip: FC<TasksCountChipProps> = (props): ReactElement => {
  const { type = null } = props;
  const theme = useTheme();

  const productivityCount = useSelector(
    (state: RootState) => state.tasksCount.productivityTasks,
  );
  const draftsCount = useSelector(
    (state: RootState) => state.tasksCount.draftTasks,
  );

  const getCount = () => {
    switch (type) {
      case TasksTabEnum.Draft:
        return draftsCount;
      case TasksTabEnum.Productivity:
        return productivityCount;
      default:
        return draftsCount + productivityCount;
    }
  };

  const count = getCount();

  if (count <= 0) return

  return (
    <Fade in={count > 0}>
      <Chip
        sx={{
          bgcolor: theme.palette.primary.main,
          borderRadius: 2,
          color: theme.palette.primary.contrastText,
          height: 17,
          minWidth: 20,
          "& .MuiChip-label": {
            fontWeight: "bolder",
            fontSize: 12,
            padding: theme.spacing(0.5),
          },
        }}
        size="small"
        label={count > 99 ? "99+" : count}
      />
    </Fade>
  );
};

export default TasksCountChip;
