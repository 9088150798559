import {
  Button,
  ButtonProps,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import { Box, lighten } from "@mui/system";
import React, { useState } from "react";
import { NAVBAR_WIDTH_CLOSED, NAVBAR_WIDTH_OPENED } from "../../../constants/navbar/tabs";

interface NavButtonProps extends ButtonProps {
  navOpen: boolean;
  selected: boolean;
  alwaysShowEndIcon?: boolean;
  children: React.ReactNode;
}

const StyledButton = styled(Button)(({ theme }) => ({
  ":hover": {
    backgroundColor: lighten(theme.palette.action.focus, 0.5),
  },
  textAlign: "left",
  minHeight: 33,
  borderRadius: 10,
  paddingLeft: 10, paddingRight: 10,
  width: '99%',
  textTransform: "capitalize",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ":hover": {
    backgroundColor: lighten(theme.palette.action.focus, 0.5),
  },
  minHeight: 33,
}));

const UbicoNavButton: React.FC<NavButtonProps> = ({
  children,
  navOpen,
  selected,
  startIcon,
  endIcon,
  alwaysShowEndIcon = false,
  ...props
}) => {
  const theme = useTheme();
  const [showEndIcon, setShowEndIcon] = useState(false);
  return (
    <Box
      pl={1} pr={1} pb={1} display={'flex'} justifyContent="center"
      width={navOpen ? NAVBAR_WIDTH_OPENED : NAVBAR_WIDTH_CLOSED}
    >
      {navOpen ? (
        <StyledButton
          {...props}
          sx={{
            justifyContent: navOpen ? "initial" : "center",
            bgcolor: selected
              ? lighten(theme.palette.action.focus, 0.5)
              : "transparent",
            color: selected
              ? theme.palette.primary.main
              : theme.palette.text.primary,
            "& .MuiButton-endIcon": {
              position: "absolute",
              right: "1rem",
            },
          }}
          startIcon={startIcon}
          endIcon={(alwaysShowEndIcon || showEndIcon) && endIcon}
          onMouseEnter={() => setShowEndIcon(true)}
          onMouseLeave={() => setShowEndIcon(false)}
        >
          {navOpen && children}
        </StyledButton>
      ) : (
        <StyledIconButton
          {...props}
          sx={{
            bgcolor: selected ? theme.palette.divider : "transparent",
            width: 33,
            height: 33,
            fontSize: 10
          }}
        >
          {startIcon}
        </StyledIconButton>
      )}
    </Box>
  );
};

export default UbicoNavButton;
