import {
  AccountCircle,
  Dns,
  Notifications,
  People,
  ReceiptLong,
  Sync,
  Unsubscribe,
  ViewList,
  Workspaces
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import UbicoProfileAvatar from "../components/custom/avatars/ProfileAvatar";
import {
  ADMIN_ROLE,
  COLLABORATOR_ROLE,
  NORMAL_USER_ROLE,
  WORKSPACE_MANAGER_ROLE,
} from "../constants/user-roles";
import {
  AccountDetails,
  AccountSync,
  AccountSyncSettings,
  AddNewsletterDomain,
  BillingManagement,
  BillingOverview,
  NewsletterDomainDetails,
  NewsletterDomains,
  NotificationPreferences,
  ProfileDetails,
  TeamDetails,
  WorkspaceBlacklist,
  WorkspaceDetails,
  WorkspaceManagement
} from "../loadables";
import { Route } from "./routeInterface";

export const settingsRoutes: Array<Route> = [
  {
    key: "profile-details-route",
    label: "Profile details",
    path: "profile-details",
    enabled: true,
    component: ProfileDetails,
    isTopTab: true,
    tabInfo: {
      id: "profile-details",
      startIcon: <UbicoProfileAvatar selfProfile width={20} height={20} />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
    group: "Profile",
  },
  {
    key: "email-sync-route",
    label: "Campaign Emails",
    path: "email-sync",
    enabled: true,
    component: AccountSync,
    isTopTab: true,
    tabInfo: {
      id: "email-sync/",
      startIcon: <Icon component={Sync} />,
      endIcon: null,
    },
    leastRole: NORMAL_USER_ROLE,
    group: "Emails",
  },
  {
    key: "domains-route",
    label: "Newsletter Domains",
    path: "domains",
    enabled: true,
    component: NewsletterDomains,
    isTopTab: true,
    tabInfo: {
      id: "domains/",
      startIcon: <Icon component={Dns} />,
      endIcon: null,
    },
    leastRole: NORMAL_USER_ROLE,
    group: "Emails",
  },
  {
    key: "newsletter-domain-details-route",
    label: "Newsletter Domain Details",
    path: "domains/:id",
    enabled: true,
    component: NewsletterDomainDetails,
    leastRole: NORMAL_USER_ROLE,
  },
  {
    key: "new-domain-route",
    label: "New Domain",
    path: "domains/new",
    enabled: true,
    component: AddNewsletterDomain,
    leastRole: NORMAL_USER_ROLE,
  },
  {
    key: "notifications-preferences-route",
    label: "Notifications",
    path: "notifications",
    enabled: true,
    component: NotificationPreferences,
    isTopTab: true,
    tabInfo: {
      id: "notifications-preferences",
      startIcon: <Icon component={Notifications} />,
      endIcon: null,
    },
    leastRole: NORMAL_USER_ROLE,
    group: "Profile",
  },
  {
    key: "email-sync-settings-route",
    label: "Email sync settings",
    path: "email-sync/:id",
    enabled: true,
    component: AccountSyncSettings,
    leastRole: NORMAL_USER_ROLE,
  },
  {
    key: "account-details-route",
    label: "Account details",
    path: "account-details",
    enabled: true,
    component: AccountDetails,
    isTopTab: true,
    tabInfo: {
      id: "account-details",
      startIcon: <Icon component={AccountCircle} />,
      endIcon: null,
    },
    leastRole: ADMIN_ROLE,
    group: "Account",
  },
  {
    key: "team-members-route",
    label: "Team members",
    path: "team-members",
    enabled: true,
    component: TeamDetails,
    isTopTab: true,
    tabInfo: {
      id: "team-members",
      startIcon: <Icon component={People} fontSize="small"/>,
      endIcon: null,
    },
    leastRole: ADMIN_ROLE,
    group: "Account",
  },
  {
    key: "billing",
    label: "Billing",
    path: "billing",
    enabled: true,
    component: BillingOverview,
    isTopTab: true,
    tabInfo: {
      id: "billing",
      startIcon: <Icon component={ReceiptLong} fontSize="small"/>,
      endIcon: null,
    },
    leastRole: ADMIN_ROLE,
    group: "Account",
  },
  {
    key: "billing",
    label: "Billing Management",
    path: "billing/manage",
    enabled: true,
    component: BillingManagement,
    leastRole: ADMIN_ROLE,
  },
  {
    key: "workspace-details-route",
    label: "Workspace details",
    path: "workspace-details",
    enabled: true,
    component: WorkspaceDetails,
    isTopTab: true,
    order: 0,
    tabInfo: {
      id: "workspace-details",
      startIcon: <Icon component={Workspaces} fontSize="small"/>,
      endIcon: null,
    },
    leastRole: WORKSPACE_MANAGER_ROLE,
    group: "Workspaces",
  },
  {
    key: "workspaces-route",
    label: "Workspaces",
    path: "workspaces",
    enabled: true,
    component: WorkspaceManagement,
    isTopTab: true,
    order: 0,
    tabInfo: {
      id: "workspaces",
      startIcon: <Icon component={ViewList} fontSize="small"/>,
      endIcon: null,
    },
    leastRole: ADMIN_ROLE,
    group: "Workspaces",
  },
  {
    key: "workspaces-unsubscribes-route",
    label: "Unsubscribes",
    path: "unsubscribes",
    enabled: true,
    component: WorkspaceBlacklist,
    isTopTab: true,
    order: 0,
    tabInfo: {
      id: "unsubscribes",
      startIcon: <Icon component={Unsubscribe} fontSize="small"/>,
      endIcon: null,
    },
    leastRole: ADMIN_ROLE,
    group: "Workspaces",
  },
];
