import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import accountReducer from "./reducers/accountReducer";
import accountUsageReducer from "./reducers/accountUsageReducer";
import attributesReducer from "./reducers/attributesReducer";
import authReducer from "./reducers/authReducer";
import billingReducer from "./reducers/billingReducer";
import dialogReducer from "./reducers/dialogReducer";
import jobFunctionsReducer from "./reducers/jobFunctionsReducer";
import jobSenioritiesReducer from "./reducers/jobSenioritiesReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import operatorsReducer from "./reducers/operatorsReducer";
import overviewStatsReducer from "./reducers/overviewReducer";
import profileReducer from "./reducers/profileReducer";
import schedulesReducer from "./reducers/schedulesReducer";
import sendersReducer from "./reducers/sendersReducer";
import snackbarReducer from "./reducers/snackbarReducer";
import tasksCountReducer from "./reducers/tasksCountReducer";
import teamReducer from "./reducers/teamReducer";
import timezonesReducer from "./reducers/timezonesReducer";
import workspaceReducer from "./reducers/workspaceReducer";

const LOGOUT_ACTION_NAME = "auth/setAuth";

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_ACTION_NAME) {
    if (!action?.payload?.["is_logged_in"]) {
      storage.removeItem("persist:root");
      return mainReducer(undefined, action);
    }
  }
  return mainReducer(state, action);
};

const mainReducer = combineReducers({
  snackbar: snackbarReducer,
  profile: profileReducer,
  account: accountReducer,
  accountUsage: accountUsageReducer,
  billing: billingReducer,
  workspace: workspaceReducer,
  auth: authReducer,
  dialog: dialogReducer,
  senders: sendersReducer,
  schedules: schedulesReducer,
  attributes: attributesReducer,
  operators: operatorsReducer,
  team: teamReducer,
  overviewStatisticsParams: overviewStatsReducer,
  jobSeniorities: jobSenioritiesReducer,
  jobFunctions: jobFunctionsReducer,
  notifications: notificationsReducer,
  tasksCount: tasksCountReducer,
  timezones: timezonesReducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage: storage,
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
