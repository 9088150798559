import { getDesignTokens } from "./theme";

// Overriding defaults on MUI components
const getComponentsTheme = (mode) => {
  const defaultTheme = getDesignTokens(mode);
  return {
    components: {
      // We added this theme since we had issues with MUI chart tooltip zIndex. We needed to
      // override the main Popper component to make it work.
      // TODO - find a way to avoid having to override the main Popper component's zIndex.
      MuiPopper: {
        styleOverrides: {
          root: {
            zIndex: 100000,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            lineHeight: 2.5,
            backgroundColor: defaultTheme.palette.background.light,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small",
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "0.8rem",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            backgroundColor: defaultTheme.palette.background.paper,
            fontSize: "0.8rem",
          },
        },
      },
    },
  };
};

export default getComponentsTheme;
