import { CssBaseline, PaletteMode, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { createContext, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import getComponentsTheme from "./componentsTheme";
import { ColorMode } from "./constants/color-mode";
import store from "./redux/store";
import { getDesignTokens } from "./theme";

export const ColorModeContext = createContext({
  mode: ColorMode.Light,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMode: (mode: ColorMode) => {},
});

const Providers: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactElement => {
  const stored = localStorage?.getItem("theme_mode");

  const [mode, setMode] = React.useState<ColorMode>(
    stored ? stored as ColorMode : ColorMode.Light,
  );
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const currentMode = useMemo(() => {
    let newMode = ColorMode.Light
    if (mode === ColorMode.Light) {
      newMode = ColorMode.Light;
    } else if (mode === ColorMode.Dark) {
      newMode = ColorMode.Dark;
    } else {
      newMode = prefersDarkMode ? ColorMode.Dark : ColorMode.Light; // system preference
    }
    localStorage?.setItem("theme_mode", mode);
    return newMode
  }, [mode, prefersDarkMode]);

  // Update the theme only if the mode changes
  const appTheme = React.useMemo(
    () => createTheme(getDesignTokens(currentMode as PaletteMode), getComponentsTheme(currentMode)),
    [currentMode],
  );

  useEffect(() => {
    // Set system color mode is not selected yet.
    if(!stored) {
      localStorage.setItem("theme_mode", ColorMode.System)
      setMode(ColorMode.System)
    }
  }, [stored])

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={{ mode, setMode }}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
};

export default Providers;
