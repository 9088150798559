import {
  Extension
} from "@mui/icons-material";
import { Icon, SvgIcon } from "@mui/material";
import { ReactComponent as SparkleIcon } from "../assets/icons/sparkle.svg";
import ubicoColors from "../assets/variables/colors.scss";
import {
  NORMAL_USER_ROLE
} from "../constants/user-roles";
import {
  IntegrationDetailsPage,
  Integrations
} from "../loadables";
import { Route } from "./routeInterface";

export const integrationRoutes: Array<Route> = [
  {
    key: "integrations-route",
    label: "Integrations",
    path: "integrations",
    enabled: true,
    component: Integrations,
    isTopTab: true,
    order: 6,
    tabInfo: {
      id: "integrations",
      startIcon: <Icon component={Extension} fontSize="small"/>,
      endIcon: (
        <SvgIcon component={SparkleIcon} sx={{ color: ubicoColors.primary }} fontSize="small"/>
      ),
      alwaysShowEndIcon: true,
    },
    leastRole: NORMAL_USER_ROLE,
  },
  {
    key: "integration-configurations-route",
    label: "Integrations",
    path: `integrations/:integrationType`,
    enabled: true,
    component: IntegrationDetailsPage,
    leastRole: NORMAL_USER_ROLE,
  }
]
